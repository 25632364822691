.card {
    padding: 3px;
    border: gray thin solid;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-28 0:0:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.current .swirl-in-fwd {
    -webkit-animation: swirl-in-fwd 0.6s ease-out both;
    animation: swirl-in-fwd 0.6s ease-out both;
}

/**
 * ----------------------------------------
 * animation swirl-in-fwd
 * ----------------------------------------
 */

@-webkit-keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}

@keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}
