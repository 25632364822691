.wins {
    font-size: medium;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin: 3px;
}

.hand {
    padding-bottom: 15px;
}

.hand .selected {
    margin-top: -10px;
    animation: color-change-2x 0.42s linear infinite alternate both;
}

.knocked h2:before {
    content: "👊 ";
}

.up h2:before {
    content: "🍆 ";
}

.disconnected {
    color: gray;
}

.disconnected h2:before {
    content: "😴 ";
}

.bot h2:before {
    content: "🤖 ";
}

.current.winner:before,
.winner h2:before {
    content: "🏆 ";
}

.pulsate-fwd {
    -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
    animation: pulsate-fwd 0.5s ease-in-out infinite both;
}

.avatar {
    height: 1em;
    border-radius: 50%;
    margin: 5px;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-5-30 11:0:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */

@-webkit-keyframes pulsate-fwd {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulsate-fwd {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-6-2 16:50:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */
@keyframes color-change-2x {
    0% {
        background: gold;
    }
    100% {
        background: white;
    }
}
