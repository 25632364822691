body {
  font-size: larger;
  font-family: 'Ubuntu', sans-serif;
  background-color: rgb(242, 242, 242);
}

body.dark {
  background-color: rgb(0, 36, 0);
  color: #ddd;
}

body.dark .active-tables a {
  color: #ddd;
}

h1 {
  text-align: center;
  margin: 25px;
}

.lobby {
  margin-top: 15px;
  display: grid;
  justify-content: center;
}

.lobby form {
  max-width: 300px;
}

.lobby input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  font-size: 1rem;
}

.lobby .password-labels {
  display: flex;
  justify-content: space-between;
}

.password-labels span {
  cursor: pointer;
}

.user-id {
  font-size: small;
}

button.join, button.rules {
  background-color: #1a72e8;
}

button.leaderboard {
  background-color: ForestGreen;
}

button.leave {
  background-color: #aa2c52;
}

button.bot {
  background-color: rebeccapurple;
}

button.discord {
  background-color: #7289da;
}

button.discord svg {
  margin-right: 5px;
}

button.login, button.logout {
  background-color: dimgray;
}

.controls a {
  text-decoration: none;
}

button {
  width: 95%;
  margin: 2.5%;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: 'Ubuntu', sans-serif;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.6);
}

button span[role="img"] {
  font-size: x-large;
}

button:active, button.active {
  text-shadow: 0 0 5px #ffd, 0 0 8px #fff;
}

button:active {
  box-shadow: 0 1px 0 #666, 0 2px 0 #444, 0 2px 2px rgba(0, 0, 0, 0.9);
  transform: translateY(3px);
}

button:focus {
  outline: none;
}

button.deal {
  background-color: #e6c300;
}

button.dark-mode {
  background-color: white;
  color: black;
}

body.dark button.dark-mode {
  background-color: black;
  color: white;
}

.card.♦️, .card.♥️, .♦️, .♥️ {
  color: red;
}

.card {
  color: black;
}

.current, .cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.round {
  margin: 10px;
  padding: 5px;
  border: solid gray;
  border-radius: 5px;
}

.rank {
  display: inline-block;
  width: 25px;
}

.joined {
  display: none;
}

body {
  overflow-x: hidden;
}

.bombed {
  animation: fadeInAnimation ease-out 7s;
  animation-iteration-count: 1;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    background-color: white;
  }
  20% {
    opacity: 0;
    background-color: white;
  }
  50% {
    opacity: 0.5;
    background-color: white;
  }
  100% {
    opacity: 1;
  }
}
