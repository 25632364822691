button.play, button.play::before {
  background-color: #4CAF50;
  content: "Play ";
}

button.waiting, button.waiting::before {
  cursor: not-allowed;
  background-color: gray;
  content: "Waiting ";
}

button.knock, button.knock::before {
  background-color: #dc3545;
  content: "Knock ";
}

button.pick, button.pick::before {
  cursor: not-allowed;
  background-color: rgb(40, 96, 42);
  content: "Pick a Card ";
}
